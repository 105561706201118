import type { AxiosInstance } from 'axios'

import { POST_DATA_IMPORTER_JWT_TOKEN_URI } from '@lib/DTO/data-importer/auth/post-jwt-token.dto'
import { POST_ONE_SCHEMA_UPLOAD_URI } from '@lib/DTO/data-importer/post-one-schema-upload.dto'

export const useDataImporterApi = (http: AxiosInstance) => ({
  getJwtToken(dataSourceId: string, calcMethodCode: string) {
    return http.post(POST_DATA_IMPORTER_JWT_TOKEN_URI, { dataSourceId, calcMethodCode })
  },

  oneSchemaDataUpload(orgId: string, embedId: number, token: string, fileId: string) {
    return http.post(
      POST_ONE_SCHEMA_UPLOAD_URI,
      { embedId, token, fileId },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },
})
